/* eslint-disable @typescript-eslint/no-redeclare */
import { Array, Dictionary, Number, Record, Static, String, Unknown } from 'runtypes';
import * as st from 'simple-runtypes';
import { DateRuntype, DateTimeRuntype, EndDateTimeRuntype } from '../types/validations/date-runtype';

export const NormTime = st.sloppyRecord({
    normTimeSNO: st.number(),
    normWorkersSNO: st.number(),
    normTeamsSNO: st.number(),
    normTimeTypesSNO: st.number(),
    timeStart: DateTimeRuntype,
    timeEnd: EndDateTimeRuntype,
    date: DateRuntype,
    origin: st.string(),
    normTeamsName: st.string(),
    isWorking: st.number(),
    isZombieShift: st.number(),
    note: st.union(
        st.sloppyRecord({
            normTimeNotesSNO: st.number(),
            normTimeSNO: st.number(),
            employeesSNO: st.number(),
            note: st.string(),
            created: st.string(),
            updated: st.string(),
        }),
        st.null()
    ),
});

export const NormTimes = st.array(NormTime);

export const NormTimeUsageDTO = Dictionary(
    Record({
        normTimeType: String,
        normTimeTypeName: String,
        normTimeTypesSNO: Number,
        hoursSpent: Number,
        shifts: Array(
            Record({
                durationInSeconds: Number,
                timeEnd: String,
                timeStart: String,
            })
        ),
    }),
    String
);

export const NormTimeUsageResponse = NormTimeUsageDTO.Or(Array(Unknown));

export const NormTimeHeadCountDTO = Dictionary(
    Record({
        teams: Dictionary(Number /* teamsSNO */, Number /* headcount on team level */),
        total: Number,
    }),
    String /* date time in ISO format, e.g. 2023-01-30 09:00 */
);
export const NormTimeHeadCountResponse = NormTimeHeadCountDTO.Or(Array(Unknown));

export type NormTimeDTO = ReturnType<typeof NormTime>;

export type NormTimeHeadCountDTO = Static<typeof NormTimeHeadCountDTO>;
export type NormTimeHeadCountRequest = {
    normPeriodsSNO: number;
    dateStart: string; // e.g. 2022-05-31
    dateEnd: string; // e.g. 2022-05-31
};
export type NormTimeHeadCountResponse = Static<typeof NormTimeHeadCountResponse>;

export type NormTimeUsageDTO = Static<typeof NormTimeUsageDTO>;
export type NormTimeUsageResponse = Static<typeof NormTimeUsageResponse>;

export const WinningTime = st.sloppyRecord({
    normTimeSNO: st.number(),
    normWorkersSNO: st.number(),
    normTeamsSNO: st.number(),
    normTeamsName: st.string(),
    normTimeTypesSNO: st.number(),
    timeStart: DateTimeRuntype,
    timeEnd: EndDateTimeRuntype,
    origin: st.string(),
    employeeName: st.string(),
    hasPendingTimeRequestChange: st.optional(st.number()),
    note: st.union(
        st.sloppyRecord({
            normTimeNotesSNO: st.number(),
            normTimeSNO: st.number(),
            employeesSNO: st.number(),
            note: st.string(),
            created: st.string(),
            updated: st.string(),
        }),
        st.null()
    ),
    timeType: st.sloppyRecord({
        normTimeTypesSNO: st.number(),
        name: st.string(),
        nameShort: st.string(),
        type: st.string(),
        timeUnit: st.string(),
        colorCodeText: st.string(),
        colorCodeBackground: st.string(),
        isWorking: st.number(),
        isVacation: st.number(),
        isSick: st.number(),
    }),
});

const WinningTimeType = st.sloppyRecord({
    normTimeTypesSNO: st.number(),
    name: st.string(),
    nameShort: st.string(),
    type: st.string(),
    timeUnit: st.string(),
    colorCodeText: st.string(),
    colorCodeBackground: st.string(),
    categoryType: st.string(),
    isWorking: st.number(),
    isVacation: st.number(),
    isSick: st.number(),
    note: st.union(
        st.sloppyRecord({
            normTimeNotesSNO: st.number(),
            normTimeSNO: st.number(),
            employeesSNO: st.number(),
            note: st.string(),
            created: st.string(),
            updated: st.string(),
        }),
        st.null()
    ),
});

export const WorkplanGanttEntry = st.sloppyRecord({
    employeesSNO: st.number(),
    normTeamsSNO: st.nullOr(st.number()),
    employeeRole: st.string(),
    employeeName: st.string(),
    teamName: st.nullOr(st.string()),
    winningTime: st.array(WinningTime),
});

export type WorkplanGanttWorker = ReturnType<typeof WorkplanGanttEntry>;

export const WorkplanGantt = st.array(WorkplanGanttEntry);

export type WorkplanGantt = ReturnType<typeof WorkplanGantt>;

export type WinningTime = ReturnType<typeof WinningTime>;

export type WinningTimeType = ReturnType<typeof WinningTimeType>;

export const EmployeeNormTime = st.sloppyRecord({
    date: DateRuntype,
    normTeamsName: st.string(),
    normTimeSNO: st.number(),
    normTimeTypesSNO: st.number(),
    normTeamsSNO: st.number(),
    normWorkersSNO: st.number(),
    origin: st.string(),
    timeStart: DateTimeRuntype,
    timeEnd: EndDateTimeRuntype,
});

export const EmployeeNormTimeList = st.array(EmployeeNormTime);

export type EmployeeNormTime = ReturnType<typeof EmployeeNormTime>;

const NormTimeCount = st.sloppyRecord({
    name: st.string(),
    categorySNO: st.number(),
    count: st.number,
    normTimeSNOs: st.array(st.number()),
});

export const NormTimeCounts = st.sloppyRecord({
    countByCategory: st.array(NormTimeCount),
});
export type NormTimeCounts = ReturnType<typeof NormTimeCounts>;

const NormTimeTypeCount = st.sloppyRecord({
    name: st.string(),
    normTimeTypesSNO: st.number(),
    count: st.number,
    normTimeSNOs: st.array(st.number()),
});

export const NormTimeTypeCounts = st.sloppyRecord({
    countByTimeType: st.array(NormTimeTypeCount),
});
export type NormTimeTypeCounts = ReturnType<typeof NormTimeTypeCounts>;

export const OpenerOrCloserShifts = st.sloppyRecord({
    normTimeSNO: st.number(),
});

export const OpenersAndClosersShifts = st.sloppyRecord({
    openers: st.array(OpenerOrCloserShifts),
    closers: st.array(OpenerOrCloserShifts),
});

export type OpenersAndClosersShifts = ReturnType<typeof OpenersAndClosersShifts>;
